import { graphql } from "gatsby";
import React from "react";
import BalanzUniversityLayout from "../../components/balanz-university/BalanzUniveristyLayout";
import ArticleList from "../../components/balanz-university/ArticleList";

const pageData = {
    title: 'Artículos de Educación Financiera | Balanz University',
    description: 'Leé nuestros artículos destacados y aprendé todo sobre el mercado de capitales, finanzas personales, activos financieros, análisis técnico y más.',
    bannerText: null
};

const BalanzUniversityArts = ({data}) => {
    return <BalanzUniversityLayout pageData={pageData}>
        <ArticleList artdata={data.allUniversityArticles.edges} />
    </BalanzUniversityLayout>
}

export const query = graphql`
    query {
        allUniversityArticles {
            edges {
                node {
                    slug
                    title
                    description
                    category
                    date
                    timestamp
                    imgUrl
                    highlighted
                    artid
                    visits
                    order
                    metatitle
                    htmldesc
                    section
                    htmlcontent
                }
            }
        }
    }
`
export default BalanzUniversityArts